/* eslint-disable no-param-reassign */
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import formatDate from "../../../../helpers/formatDate";
import formatHour from "../../../../helpers/formatHour";
import Logo from "../../../../assets/images/logos/logo.png";
import assinatura from "../../../../assets/images/logos/assinatura.png";
import warningAmber from "../../../../assets/images/icons/err/warning_amber.png";
import highlightOff from "../../../../assets/images/icons/err/highlight_off.png";
import powerOff from "../../../../assets/images/icons/err/power_off_outlined.png";
import iconImportant from "../../../../assets/images/icons/err/notification_important_outlined.png";
import iconOutlet from "../../../../assets/images/icons/err/outlet_outlined.png";
import authService from "../../../../helpers/auth.service";

const GeneratePdfDaily = (props) => {
  const doc = new jsPDF();
  const currentDate = new Date();
  const formattedDate = currentDate.toISOString().slice(0, 10);
  const formattedTime = currentDate.toLocaleTimeString([], { hour12: false });
  const usr = authService.getCurrentUser();
  const data = [];
  const keys = Object.keys(props?.reportData.containers);
  keys.forEach((key) => {
    const e = [];
    const dateErr = [];
    const columnSpacing = [];
    props.reportData.containers[key].monitoring.forEach((m) => {
      if (m.errType > 0) {
        e.push(m.errType);
        const fDate = formatDate(m.read_timestamp);
        const fHour = parseFloat(formatHour(m.read_timestamp));
        if (fHour <= 12) {
          dateErr.push(
            `${fDate} - AM                                     
                                                              
                            
`
          );
        } else {
          dateErr.push(
            `${fDate} - PM                                     
                                                                
                              
  `
          );
        }
      }
    });
    data.push([
      key,
      props.reportData.travel,
      props.reportData.containers[key].temperature,
      props.reportData.containers[key].am,
      props.reportData.containers[key].pm,
      props.reportData.containers[key].err,
      dateErr.join(""),
      columnSpacing,
      e,
    ]);
  });

  const errList = {
    1: warningAmber,
    2: highlightOff,
    3: highlightOff,
    4: iconImportant,
    6: powerOff,
    8: iconOutlet,
  };

  doc.setFontSize(8).setTextColor("#00000");
  doc.text(`Relatório de Monitoramento - ${props?.ship}`, 10, 10);
  doc.text(`${props?.patio}`, 100, 10);
  doc.text(`${props?.InitDate}`, 170, 10);

  doc.addImage(Logo, "PNG", 76, 14, 50, 20); // x, y, width, height, alias, compression, rotation

  doc.setFont("courier", "bold").setFontSize(6).setTextColor("#00008b");
  doc.text("CNPJ 09.380.074.0001/75", 86, 36);

  doc.setFont("courier", "bold").setFontSize(10).setTextColor("#00000");
  doc.text("DIÁRIO DE MONITORAMENTOS", 74, 40);

  doc.setFontSize(7).setTextColor("#00000");

  doc.addImage(warningAmber, "PNG", 40, 46, 16, 12);
  doc.text("Baixo Rendimento", 38, 60);
  doc.text("do Container", 41, 64);

  doc.addImage(highlightOff, "PNG", 70, 46, 16, 12);
  doc.text("Temperatura", 70, 60);
  doc.text("Crítica", 73, 64);

  doc.addImage(iconImportant, "PNG", 100, 46, 18, 12);
  doc.text("Container com", 100, 60);
  doc.text("Problema Crítico", 99, 64);

  doc.addImage(powerOff, "PNG", 130, 46, 16, 12);
  doc.text("Container Sem", 129, 60);
  doc.text("Alimentação", 131, 64);

  doc.addImage(iconOutlet, "PNG", 160, 46, 16, 12);
  doc.text("Falta de Energia", 158, 60);
  doc.text("no Pátio", 164, 64);

  console.log(props);
  autoTable(doc, {
    head: [
      [
        "CONTAINER",
        "VIAGEM DE ENTRADA",
        "SETPOINT",
        "AM",
        "PM",
        "FALHAS",
        "EVIDÊNCIAS DE PROBLEMAS",
      ],
    ],
    startY: 70,
    showHead: "firstPage",
    headStyles: { textColor: "gray", fillColor: null, fontSize: 5 },
    bodyStyles: { theme: "grid", fontSize: 7, valign: "top" },
    body: data,
    didDrawCell: (d) => {
      if (d.section === "body" && d.column.index === 6) {
        let MarginY = 0;
        d.cell.y += 6;
        d.row.raw[8].forEach((errCode) => {
          if (errCode < 10) {
            doc.addImage(errList[errCode], "PNG", d.cell.x + 2 * 2, d.cell.y + MarginY, 10, 8);
            MarginY += 18;
          }
        });
      }
    },
  });

  let initialY = doc.lastAutoTable.finalY + 10;
  if (initialY > 190) {
    doc.addPage();
    doc.setPage(doc.getNumberOfPages());
    initialY = 10;
  } else {
    initialY = 196;
  }

  doc.setFontSize(8).setTextColor("#00000");
  doc.text(`Total de Containers: ${props.reportData.totalContainers}`, 74, initialY);
  doc.text(`Total de Monitoramentos: ${props.reportData.totalMonitoring}`, 74, initialY + 6);

  usr.isSysAdmin === 1 || usr.isFinancial === 1 || usr.AccountAdmin === 1
    ? doc.text(`Valor Total: R$ ${props.reportData.totalMonitoring * 4.5}`, 74, initialY + 12)
    : null;

  doc.addImage(assinatura, "PNG", 84, initialY + 22, 30, 30);

  doc.setFont("Courier", "bold").setFontSize(10).setTextColor("#00000");
  doc.text("Francisco de Paula Luca", 74, initialY + 58);

  doc.setFont("Courier", "bold").setFontSize(9).setTextColor("#00000");
  doc.text("CREA-AM 13.225D", 88, initialY + 64);

  doc.setFont("Courier", "bold").setFontSize(8).setTextColor("#00000");
  doc.text("Engenheiro Proprietário", 79, initialY + 70);

  const footerDesc =
    "Escritório: Rua Professor Guilherme Nery nº 480 - Parque 10 de novembro - CEP 69.055-350 - Manaus/AM";
  doc.setFont("Courier", "*").setFontSize(9).setTextColor("#00000");
  doc.text(footerDesc, 20, initialY + 80);
  doc.text("SGCF v.: 0.9.95/2022 acesso em 17/10/2022 21:08:54", 118, initialY + 84);

  doc.save(`Relatorio-diário-${props?.ship}-${formattedDate}-${formattedTime}.pdf`);
};

export default GeneratePdfDaily;
