/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import * as React from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import DataTable from "../Tables/DataTable";

export default function ReportBodyMonthly(props) {
  const { t } = useTranslation("common");
  const [rows, setRows] = React.useState([]);
  const [totalValue, setTotalValue] = React.useState(0);

  const columns = [
    { Header: t("reports.closing_ship"), accessor: "ship", align: "center" },
    { Header: t("table_mov.closing_travel"), accessor: "travel", align: "left" },
    {
      Header: t("table_mov.closing_total_containers"),
      accessor: "total_containers",
      align: "left",
    },
    { Header: t("table_mov.closing_total_monit"), accessor: "total_monit", align: "center" },
    { Header: t("table_mov.closing_value"), accessor: "value", align: "center" },
  ];

  React.useEffect(() => {
    if (rows.length > 0) {
      return;
    }
    const valueMonitoringUser = props?.content.valuePerMonitoring;
    const tmpRow = [];
    let tmpTotal = 0;
    props.content.keys.forEach((key) => {
      tmpTotal += props.content.reportData[key].totalMonitoring * valueMonitoringUser;
      tmpRow.push({
        ship: key,
        travel: props.content.reportData[key].travel,
        total_containers: props.content.reportData[key].totalContainers,
        total_monit: props.content.reportData[key].totalMonitoring,
        value: new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(
          props.content.reportData[key].totalMonitoring * valueMonitoringUser
        ),
        
      });
    });
    setRows(tmpRow);
    setTotalValue(tmpTotal);
  }, []);
  return (
    <>
      <div style={{ width: "100%" }}>
        <DataTable
          table={{ columns, rows }}
          isSorted={false}
          entriesPerPage={false}
          showTotalEntries={false}
          noEndBorder
        />
      </div>
      <Grid container>
        <Grid xs={12} mt={4} mb={4} item>
          <Box style={{ display: "flex", justifyContent: "center" }} className="text-title">
            {`${t("reports.title_totalpay_monitoring")} ${new Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
            }).format(totalValue)}`}
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
