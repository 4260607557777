/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import * as React from "react";
import axios from "axios";

// Material Dashboard 2 React components
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import Stack from "@mui/material/Stack";
import { Box } from "@mui/material";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import MDButton from "../../components/MDButton";
import MDTypography from "../../components/MDTypography";
import MDInput from "../../components/MDInput";
import MDBox from "../../components/MDBox";

import authService from "../../helpers/auth.service";
import config from "../../config/config.json";
import authHeader from "../../helpers/auth-headers";

export default function ModalContacts(props) {
  const { t } = useTranslation("common");
  const user = authService.getCurrentUser();
  const { API_URL } = config;

  const [firstName, setFirstName] = React.useState("");

  // patios
  const [patios, setPatios] = React.useState([]);
  const [selectedPatios, setSelectedPatios] = React.useState([]);

  // carriers
  const [carriers, setCarriers] = React.useState([]);
  const [selectedCarriers, setSelectedCarriers] = React.useState([]); // Armazena os pátios selecionados

  // more
  const [phone, setPhone] = React.useState("");
  const [mail, setMail] = React.useState("");
  const [accountList, setAccountList] = React.useState([]);
  const [isActive, setIsActive] = React.useState(1);

  console.log(props);
  React.useEffect(async () => {
    if (props.contact) {
      setFirstName(props.contact?.name);
      setMail(props.contact?.mail);
      setPhone(props.contact?.phone);
      setIsActive(props.contact?.isActive === 1);
      setSelectedPatios(props.contact?.isPatios);
      setSelectedCarriers(props.contact?.isCarriers);
    }
    if (accountList.length === 0) {
      const response = await axios.get(`${API_URL}accounts`, { headers: authHeader() });
      setAccountList(response.data);
    }
  }, []);

  const handleSave = () => {
    const contact = {
      idcontatos: props.contact?.idcontatos ? props.contact.idcontatos : null,
      data: {
        name: firstName,
        mail,
        phone,
        isActive,
        isCarriers: selectedCarriers,
        isPatios: selectedPatios,
      },
    };
    props.onClose(contact);
    console.log(contact);
  };

  React.useEffect(() => {
    const patioList = async () => {
      axios.get(`${API_URL}patios/`, { headers: authHeader() }).then(
        (response) => {
          setPatios(response.data);
          console.log(response.data);
        },
        (error) => {
          console.log(error);
        }
      );
    };
    patioList();
  }, []);

  React.useEffect(() => {
    const carrierList = async () => {
      axios.get(`${API_URL}carriers/`, { headers: authHeader() }).then(
        (response) => {
          setCarriers(response.data.slice().sort((a, b) => a.name.localeCompare(b.name)));
          console.log(response.data);
        },
        (error) => {
          console.log(error);
        }
      );
    };
    carrierList();
  }, []);

  const handlePatioSelection = (selectedOptions) => {
    // Remove duplicates by creating a new Set and then converting it back to an array
    const uniqueSelectedOptions = Array.from(
      new Set(selectedOptions.map((option) => JSON.stringify(option)))
    );

    setSelectedPatios(uniqueSelectedOptions.map((jsonString) => JSON.parse(jsonString)));
  };

  const handleCarrierSelection = (selectedOptions) => {
    // Remove duplicates by creating a new Set and then converting it back to an array
    const uniqueSelectedOptions = Array.from(
      new Set(selectedOptions.map((option) => JSON.stringify(option)))
    );

    setSelectedCarriers(uniqueSelectedOptions.map((jsonString) => JSON.parse(jsonString)));
  };

  console.log(props.contact);
  return (
    <Grid container xs={12} className="modal-transp">
      <Grid item xs={12} sm={9} md={8} lg={5} xl={4}>
        <MDBox className="card-modal" sx={{ bgcolor: "#ffff" }}>
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
            mx={3}
            mt={-4}
            p={2}
            mb={3}
            textAlign="center"
            sx={{ width: "90%", bgcolor: "#888" }}
          >
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              <Box>{props.contact === null ? t("modal.mod_new_contacts") : props.title}</Box>
            </MDTypography>
          </MDBox>
          <Grid container>
            <Grid item xs={12} sx={{ width: "100%" }}>
              <FormControl variant="standard" sx={{ m: 1, p: 1, width: "95%" }}>
                <MDInput
                  id="outlined-basic"
                  onChange={(e) => setFirstName(e.target.value)}
                  value={firstName}
                  label={t("modal.mod_input_name")}
                  variant="standard"
                  fullWidth
                  size="string"
                  style={{ height: "2rem" }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sx={{ width: "100%" }}>
              <FormControl variant="standard" sx={{ m: 1, p: 1, width: "95%" }}>
                <MDInput
                  id="outlined-basic"
                  onChange={(e) => setMail(e.target.value)}
                  value={mail}
                  label={t("modal.mod_input_email")}
                  variant="standard"
                  fullWidth
                  size="string"
                  style={{ height: "2rem" }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6} sx={{ width: "100%" }}>
              <FormControl variant="standard" sx={{ m: 1, p: 1, width: "95%" }}>
                <MDInput
                  id="outlined-basic"
                  onChange={(e) => setPhone(e.target.value)}
                  value={phone}
                  label={t("modal.mod_input_phone")}
                  variant="standard"
                  fullWidth
                  size="string"
                  style={{ height: "2rem" }}
                />
              </FormControl>
            </Grid>

            {/* Carriers */}
            <Grid item sx={{ width: "100%" }}>
              <FormControl variant="standard" sx={{ m: 1, p: 1, width: "95%" }}>
                <Autocomplete
                  multiple
                  id="multiple-limit-tags"
                  options={carriers.filter(
                    (carrier) =>
                      !selectedCarriers.some(
                        (selected) => selected.idcarriers === carrier.idcarriers
                      )
                  )}
                  getOptionLabel={(option) => option.name}
                  disableCloseOnSelect
                  value={selectedCarriers || []}
                  onChange={(event, newValue) => handleCarrierSelection(newValue)}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        key={option.idcarriers}
                        label={option.name}
                        {...getTagProps({ index })}
                        style={{ backgroundColor: "#696969", color: "white" }}
                      />
                    ))
                  }
                  renderInput={(params) => <TextField {...params} label="Carriers" />}
                  noOptionsText="Todos os carriers disponíveis foram selecionados!"
                />
              </FormControl>
            </Grid>

            {/* patios */}
            <Grid item sx={{ width: "100%" }}>
              <FormControl variant="standard" sx={{ m: 1, p: 1, width: "95%" }}>
                <Autocomplete
                  multiple
                  id="multiple-limit-tags"
                  disableCloseOnSelect
                  options={patios.filter(
                    (patio) =>
                      !selectedPatios.some((selected) => selected.idpatios === patio.idpatios)
                  )}
                  getOptionLabel={(option) => option.name}
                  value={selectedPatios || []}
                  onChange={(event, newValue) => handlePatioSelection(newValue)}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        key={option.idpatios}
                        label={option.name}
                        {...getTagProps({ index })}
                        style={{ backgroundColor: "#696969", color: "white" }}
                      />
                    ))
                  }
                  renderInput={(params) => <TextField {...params} label="Pátios" />}
                  noOptionsText="Todos os pátios disponíveis foram selecionados!"
                />
              </FormControl>
            </Grid>
            <Grid container style={{ justifyContent: "right", alignItems: "center" }}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={6} xxl={6} p={1}>
                <Stack direction="row">
                  <FormControl
                    sx={{ p: 1, width: "100%" }}
                    style={{ justifyContent: "center", alignItems: "center" }}
                  >
                    <MDButton
                      onClick={() => props.onClose(null)}
                      sx={{ width: "100%" }}
                      variant="gradient"
                      color="info"
                      style={{ height: "3rem" }}
                    >
                      {t("modal.mod_cancel")}
                    </MDButton>
                  </FormControl>
                  <FormControl
                    sx={{ p: 1, width: "100%" }}
                    style={{ justifyContent: "center", alignItems: "center" }}
                  >
                    <MDButton
                      onClick={handleSave}
                      sx={{ width: "100%" }}
                      variant="gradient"
                      color="info"
                      style={{ height: "3rem" }}
                    >
                      {t("modal.mod_save")}
                    </MDButton>
                  </FormControl>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </MDBox>
      </Grid>
    </Grid>
  );
}
