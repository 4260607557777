/* eslint-disable react/jsx-key */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-expressions */
// @mui material components
import * as React from "react";
import Card from "@mui/material/Card";
import axios from "axios";
import "../../index.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// dropzone
import Dropzone from "react-dropzone";

// Material Dashboard 2 React components
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { Grid, ImageList, ImageListItem } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import TextField from "@mui/material/TextField";
import Icon from "@mui/material/Icon";
import SaveIcon from "@mui/icons-material/Save";
import SendIcon from "@mui/icons-material/Send";
import MenuItem from "@mui/material/MenuItem";
import { ThemeProvider, createTheme } from "@mui/material/styles";
// import formatDate from "../../helpers/formatDate";
import moment from "moment";
import authService from "../../helpers/auth.service";
import MDInput from "../../components/MDInput";
import authHeader from "../../helpers/auth-headers";
import MDButton from "../../components/MDButton";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import SysDashLayout from "../../components_reefer/Layouts/SysDashLayout";
import config from "../../config/config.json";
import ModalMaintenance from "../../components_reefer/Modals/ModalMaintenance";
import ModalAlerts from "../../components_reefer/Modals/ModalSendAlert";
// import authService from "../../helpers/auth.service";
// I18N

function SystemMaintenance() {
  const { t } = useTranslation("common");
  const { API_URL } = config;
  const [porto, setPorto] = React.useState([]);
  const [portoList, setPortoList] = React.useState([]);
  const [patio, setPatio] = React.useState([]);
  const [patioList, setPatioList] = React.useState([]);
  const [countHH, setCountHH] = React.useState(1);
  const [contrs, setContrs] = React.useState([]);
  const [selectContainer, setSelectContainer] = React.useState([]);
  const [contsList, setContsList] = React.useState([]);
  const [selectQuadra, setSelectQuadra] = React.useState([""]);
  const [selectLote, setSelectLote] = React.useState([""]);
  const [localSlct, setLocalSlct] = React.useState("Container");
  const [voltagem, setVoltagem] = React.useState(440);
  const [maintenanceType, setMaintenanceType] = React.useState([]);
  const [maintenanceList, setMaintenanceList] = React.useState([]);
  const [rows, setRows] = React.useState([{ id: 0, item: "", quantity: 0, value: 0 }]);
  const [nameImage, setNameImage] = React.useState([]);
  const [nameUp, setNameUp] = React.useState([]);
  const [image, setImage] = React.useState([]);
  const [selectSize, setSelectSize] = React.useState([]);
  const [selectManufactory, setSelectManufactory] = React.useState([]);
  const [onDescription, setOnDescription] = React.useState([]);
  const user = authService.getCurrentUser();
  const [isModalSave, setIsModalSave] = React.useState(false);
  // const [uniqueCarrier, setUniqueCarrier] = React.useState([]);
  const [users, setUsers] = React.useState([]);
  const [contacts, setContacts] = React.useState([]);
  const [validateDesc, setValidateDesc] = React.useState(false);
  const [validatePhoto, setValidatePhoto] = React.useState(false);
  const [materialList, setMaterialList] = React.useState([]);

  // retroative maintenance
  const [retroDate, setRetroDate] = React.useState(new Date().toISOString());
  // const [minDate, setMinDate] = React.useState(moment().clone().startOf("month"));

  const currentTime = moment();

  const voltage = [
    { v: 115 },
    { v: 127 },
    { v: 220 },
    { v: 230 },
    { v: 240 },
    { v: 254 },
    { v: 380 },
    { v: 440 },
  ];

  React.useEffect(() => {
    const fetchData = async () => {
      if (localSlct === "Container") {
        setSelectLote([""]);
        setSelectQuadra([""]);
      } else {
        setSelectSize([""]);
        setSelectManufactory([""]);
        setContrs([""]);
      }
    };
    fetchData();
  }, [localSlct]);

  const local = [{ name: "Container" }, { name: "Quadra / Lote" }];
  const Size = [{ name: "RF" }, { name: "20RF" }, { name: "40RF" }, { name: "40HR" }];
  const Fab = [
    { name: "STAR COOL" },
    { name: "THERMOKING" },
    { name: "CARRIER" },
    { name: "DAIKIN" },
  ];

  const theme = createTheme({
    components: {
      MuiIcon: {
        styleOverrides: {
          root: {
            fontSize: "2rem",
            color: "grey",
          },
        },
      },
    },
  });

  React.useEffect(() => {
    const fetchData = async () => {
      if (portoList.length === 0) {
        axios.get(`${API_URL}portos/${user.accountsIdaccounts}`, { headers: authHeader() }).then(
          (response) => {
            setPortoList(response.data);
            if (response.data.length === 1) {
              setPorto(response.data[0]);
            }
          },
          (error) => {
            console.log(error);
          }
        );
      }
    };
    fetchData();
  }, []);

  React.useEffect(() => {
    const fetchData = async () => {
      if (porto?.idportos) {
        axios.get(`${API_URL}patios/${porto.idportos}`, { headers: authHeader() }).then(
          (response) => {
            setPatioList(response.data);
          },
          (error) => {
            console.log(error);
          }
        );
      }
      if (porto.idportos) {
        const u = await axios.get(`${API_URL}users/account/${porto.accountsIdaccounts}`, {
          headers: authHeader(),
        });
        setUsers(u.data);
        const c = await axios.get(`${API_URL}contatos`);
        setContacts(c.data);
      }
    };
    fetchData();
  }, [porto]);

  React.useEffect(() => {
    const fetchData = async () => {
      if (patio > 0) {
        axios.get(`${API_URL}agenda/${patio}`, { headers: authHeader() }).then(
          (response) => {
            const tmpConts = [];
            response.data.forEach((conts) => {
              tmpConts.push({
                id: conts.id,
                name: conts.containers_label,
                carrier: conts.carrier,
              });
            });
            setContsList(tmpConts);
          },
          (error) => {
            console.log(error);
          }
        );
      }
    };
    fetchData();
  }, [patio]);

  React.useEffect(() => {
    const fetchData = async () => {
      if (maintenanceList.length === 0) {
        axios.get(`${API_URL}maintenance-type/`, { headers: authHeader() }).then(
          (response) => {
            setMaintenanceList(response.data);
          },
          (error) => {
            console.log(error);
          }
        );
      }
    };
    fetchData();
  }, [maintenanceList]);

  React.useEffect(() => {
    const fetchData = async () => {
      if (materialList.length === 0) {
        axios.get(`${API_URL}materials/`, { headers: authHeader() }).then(
          (response) => {
            setMaterialList(response.data);
          },
          (error) => {
            console.log(error);
          }
        );
      }
    };
    fetchData();
  }, []);

  const handleChangePorto = (event) => {
    // console.log(event.target)
    setPorto({ idportos: event.target.value, name: event.target.name });
  };

  const handleChangePatio = (event) => {
    setPatio(event.target.value);
  };

  const handleChangeConts = (event) => {
    setContrs(event.target.value);
    setSelectContainer(contsList.find((e) => e.id === event.target.value).name);
  };

  const handleChangeMaintenance = (event) => {
    setMaintenanceType(event.target.value);
  };

  const [isModalMaintenance, setIsModalMaintenance] = React.useState(false);

  const handleOpenMaintenanceModal = () => {
    setIsModalMaintenance(true);
  };

  const handleCloseMaintenanceModal = async (u) => {
    if (u === null) {
      setIsModalMaintenance(false);
      return;
    }
    const data = {
      description: u.data.description,
    };
    await axios.post(`${API_URL}maintenance-type`, data, { headers: authHeader() });
    toast.success(t("alerts.maint_type_success"));
    setIsModalMaintenance(false);
    setMaintenanceList([]);
  };

  const handleAddRow = () => {
    setRows([...rows, { id: rows.length, item: "", quantity: 0, value: 0 }]);
  };

  const handleUpdateRowNumber = (e, index, valIndex) => {
    const tmpRows = rows;
    tmpRows[index][valIndex] = parseInt(e.target.value, 10);
    setRows([...tmpRows]);
  };

  const handleUpdateRow = (e, index, valIndex) => {
    const tmpRows = rows;
    tmpRows[index][valIndex] = e.target.value;
    setRows([...tmpRows]);
  };
  const handleDrop = (acceptedFiles) => {
    // Adiciona as imagens aceitas ao estado
    setValidatePhoto(false);
    setImage([...acceptedFiles]);
  };

  const resizeImage = (file, maxWidth, maxHeight, filename) => {
    return new Promise((resolve) => {
      const img = document.createElement("img");
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      img.onload = () => {
        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
        }

        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(img, 0, 0, width, height);

        canvas.toBlob((blob) => {
          resolve(new File([blob], filename, { type: file.type }));
        }, file.type);
      };

      const reader = new FileReader();
      reader.onload = (e) => {
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    });
  };

  React.useEffect(() => {
    if (image.length === 0) {
      setNameImage([]);
      return;
    }
    let index = 0;
    let count = 0;
    const tmpNameImage = [];
    const tmpNameUp = [];
    image.forEach(async (e) => {
      count += 1;
      const formData = new FormData();
      const filename = `MAINTENANCE_IMAGE_${patio}_${new Date(Date.now())
        .toISOString()
        .split(".")[0]
        .replace(/:/g, "_")
        .replace(/-/g, "_")}_${index}.${e.name.split(".")[1]}`;
      index += 1;

      await resizeImage(e, 800, 800, filename).then((file) => {
        const formData = new FormData();
        formData.append("file", file);
        tmpNameUp.push(filename);
        tmpNameImage.push(
          `https://s3.sa-east-1.amazonaws.com/report-assets.reefersys.com/${filename}`
        );

        axios
          .post(`${API_URL}upload`, formData, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then(() => {
            count -= 1;
            if (count === 0) {
              setNameImage(tmpNameImage);
              setNameUp(tmpNameUp);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      });
    });
  }, [image]);

  React.useEffect(() => {
    console.log("====================================");
    console.log("nameImage");
    console.log(nameImage.toString());
    console.log(rows);
    console.log("====================================");
  }, [rows]);

  const imgString = nameUp.toString();
  const dateExec = new Date().toISOString();
  const data = {
    description: onDescription,
    executionDate: retroDate,
    hh: countHH,
    voltage: voltagem,
    size: selectSize,
    manufactory: selectManufactory,
    container: localSlct === "Container" ? selectContainer : selectQuadra,
    place: "retirar",
    isContainer: localSlct === "Container" ? true : false,
    quadra: `quadra - ${selectQuadra}`,
    lote: `lote - ${selectLote}`,
    patiosIdpatios: patio,
    images: imgString,
    type: maintenanceType,
    itens: rows,
  };

  const reportData = [
    {
      description: onDescription,
      executionDate: dateExec,
      hh: countHH,
      voltage: voltagem,
      size: selectSize,
      manufactory: selectManufactory,
      container: localSlct === "Container" ? selectContainer : selectQuadra,
      place: "retirar",
      isContainer: localSlct === "Container" ? true : false,
      quadra: `quadra - ${selectQuadra}`,
      lote: `lote - ${selectLote}`,
      patiosIdpatios: patio,
      photos: [imgString],
      type: maintenanceType,
      itens: rows,
    },
  ];

  const sendAlert = async (c) => {
    const tmpList = contsList;
    const unique = [...new Set(tmpList.map((item) => item.carrier))];
    const generalUsers = c.filter((item) => item.isUser === true);
    const contactsByCarrier = [];
    unique.forEach((carrier) => {
      contactsByCarrier.push({
        carrier,
        contacts: c.filter((item) => item.carrier === carrier),
        containers: tmpList.filter((item) => item.carrier === carrier),
      });
    });
    const alerts = {
      alerts: [],
    };
    contactsByCarrier.forEach((item) => {
      const emails = item.contacts.map((contact) => contact.mail);
      const phones = item.contacts.map((contact) => contact.phone);
      alerts.alerts.push({
        containers: reportData,
        email: emails,
        phone: phones,
        type: "maintenance",
      });
    });
    alerts.alerts.push({
      containers: reportData,
      email: generalUsers.map((item) => item.mail),
      phone: generalUsers.map((item) => item.phone),
      type: "maintenance",
    });
    console.log(alerts);
    const res = await axios.post(`${API_URL}alert`, alerts, { headers: authHeader() });
    console.log("status", res);
    if (res.status === 201) {
      toast.success("Alerta enviado!");
    } else {
      toast.warning("Não foi possivel enviar alerta para todos os contatos!");
    }
  };

  const saveMaintenance = async () => {
    await axios.post(`${API_URL}manutencoes`, data, { headers: authHeader() }).then(
      (response) => {
        if (response.statusText === "Created") {
          toast.success(t("alerts.maint_saved"));
          console.log(data);
          setLocalSlct("Container");
          setOnDescription("");
          setSelectSize([]);
          setSelectManufactory([]);
          setContrs([]);
          setSelectContainer([]);
          setSelectQuadra([]);
          setSelectQuadra([]);
          setSelectLote([]);
          setCountHH(1);
          setPatio([]);
          setImage([]);
          setNameImage([]);
          setMaintenanceType([]);
          setRows([{ id: "", item: "", quantity: 0, value: 0 }]);
          setNameUp([]);
          setVoltagem(440);
          setRetroDate(new Date().toISOString());
        } else {
          toast.success(t("erro ao enviar o alerta"));
        }
        console.log(response);
      },
      (error) => {
        error.response.status === 500 ? toast.error(t("alerts.maint_fill_fields")) : null;
        console.log(data);
        console.log(error);
      }
    );
  };
  const handleOpenSaveModal = () => {
    if (data.description.length === 0) {
      setValidateDesc(true);
    }
    if (data.images.length === 0) {
      setValidatePhoto(true);
    }
    if (localSlct === "Container") {
      if (
        data.description.length !== 0 &&
        data.patiosIdpatios.length !== 0 &&
        data.images.length !== 0 &&
        data.type.length !== 0 &&
        data.manufactory.length !== 0 &&
        data.size.length !== 0
      ) {
        console.log("cont enviou");
        setIsModalSave(true);
      } else {
        console.log("cont erro");
        toast.error(t("alerts.maint_fill_fields"));
      }
    } else {
      console.log("data", data);
      console.log("ptios", data.patiosIdpatios.length);
      console.log("imng", data.images.length);
      console.log("type", data.images.length);
      console.log("selectQuadra", data.quadra.length);
      console.log("selectLote", data.lote.length);
      if (
        data.description.length !== 0 &&
        data.patiosIdpatios.length !== 0 &&
        data.images.length !== 0 &&
        data.type.length !== 0 &&
        data.quadra.length !== 0 &&
        data.lote.length !== 0
      ) {
        console.log("quadra enviou");
        setIsModalSave(true);
      } else {
        console.log("quadra erro");
        toast.error(t("alerts.maint_fill_fields"));
      }
    }
  };

  const handleCloseSaveModal = async (u, save) => {
    if (save) {
      saveMaintenance();
    }
    if (u != null && u.length > 0) {
      sendAlert(u);
      // sendReport(u);
      console.log("CLOSE E U !== NULL", u);
    }
    setIsModalSave(false);
  };

  const handleChangeDate = (newValue) => {
    setRetroDate(newValue);
  };

  return (
    <SysDashLayout>
      <ToastContainer />
      <Card p={1}>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-2}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            {t("menu.maintenance")}
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={2}>
          {isModalMaintenance ? (
            <ModalMaintenance
              title={t("modal.mod_adc_maintenance")}
              onClose={handleCloseMaintenanceModal}
              // isSysAdm={user.isSysAdmin}
            />
          ) : null}
          <Grid container spacing={1} alignItems="flex-start">
            <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
              <Card>
                <Grid container spacing={4} alignItems="flex-start" px={4}>
                  <Grid item xs={11} sm={11} md={6} lg={6} xl={6} mt={2.3}>
                    <FormControl variant="standard" fullWidth>
                      <DesktopDatePicker
                        label={t("operational.retro_date")}
                        inputFormat="DD/MM/yyyy"
                        value={retroDate}
                        onChange={handleChangeDate}
                        // minDate={minDate}
                        maxDate={currentTime}
                        // onError={(e) => {
                        //   setRetroDate(e);
                        // }}
                        renderInput={(params) => <TextField variant="standard" {...params} />}
                      />
                    </FormControl>
                  </Grid>
                  {portoList.length > 1 ? (
                    <Grid item xs={11} sm={11} md={6} lg={6} xl={6}>
                      <FormControl variant="standard" fullWidth>
                        <InputLabel id="label-porto">{t("maintenance.select_harbor")}</InputLabel>
                        <Select
                          labelId="label-porto"
                          id="select-porto"
                          value={porto.idportos}
                          onChange={handleChangePorto}
                          style={{ height: "3rem" }}
                        >
                          {portoList.map((p) => (
                            <MenuItem key={p.idportos} value={p.idportos} name={p.name}>
                              {p.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  ) : null}
                  <Grid item xs={11} sm={11} md={6} lg={6} xl={6}>
                    <FormControl variant="standard" fullWidth>
                      <InputLabel id="label-patio">{t("maintenance.select_dock")}</InputLabel>
                      <Select
                        labelId="label-patio"
                        id="select-patio"
                        value={patio}
                        onChange={handleChangePatio}
                        style={{ height: "3rem" }}
                        fullWidth
                      >
                        {patioList.map((p) => (
                          <MenuItem key={p.idpatios} value={p.idpatios}>
                            {p.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={11} sm={11} md={6} lg={6} xl={6}>
                    <FormControl variant="standard" fullWidth>
                      <InputLabel id="label-patio">{t("maintenance.select_voltage")}</InputLabel>
                      <Select
                        labelId="label-voltagem"
                        id="select-voltagem"
                        value={voltagem}
                        onChange={(e) => setVoltagem(e.target.value)}
                        style={{ height: "3rem" }}
                        fullWidth
                      >
                        {voltage.map((p) => (
                          <MenuItem key={p.v} value={p.v}>
                            {p.v}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={11} sm={11} md={6} lg={6} xl={6} mt={2.5}>
                    <FormControl variant="standard" fullWidth>
                      <MDInput
                        id="outlined-basic"
                        label={t("maintenance.select_HH")}
                        variant="standard"
                        fullWidth
                        type="number"
                        style={{ height: "2rem" }}
                        value={countHH}
                        onChange={(e) => setCountHH(e.target.value)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={11} sm={11} md={6} lg={6} xl={6}>
                    <FormControl variant="standard" fullWidth>
                      <InputLabel id="label-local">{t("maintenance.select_local")}</InputLabel>
                      <Select
                        labelId="label-local"
                        id="select-local"
                        value={localSlct}
                        onChange={(e) => setLocalSlct(e.target.value)}
                        style={{ height: "3rem" }}
                        fullWidth
                      >
                        {local.map((p) => (
                          <MenuItem key={p.name} value={p.name}>
                            {p.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={11} sm={11} md={6} lg={6} xl={6}>
                    {localSlct === "Container" ? (
                      <FormControl variant="standard" fullWidth>
                        <InputLabel id="label-patio">{t("maintenance.select_size")}</InputLabel>
                        <Select
                          labelId="label-Size"
                          id="select-Size"
                          value={selectSize}
                          onChange={(e) => setSelectSize(e.target.value)}
                          style={{ height: "3rem" }}
                          fullWidth
                        >
                          {Size.map((p) => (
                            <MenuItem key={p.name} value={p.name}>
                              {p.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    ) : (
                      <FormControl variant="standard" fullWidth>
                        <MDInput
                          id="outlined-basic"
                          // onChange={handleChangePatio}
                          label={t("maintenance.select_block")}
                          variant="standard"
                          fullWidth
                          type="text"
                          style={{ height: "2rem", marginTop: "18px" }}
                          value={selectQuadra}
                          onChange={(e) => setSelectQuadra(e.target.value)}
                        />
                      </FormControl>
                    )}
                  </Grid>
                  <Grid item xs={11} sm={11} md={6} lg={6} xl={6}>
                    {localSlct === "Container" ? (
                      <FormControl variant="standard" fullWidth>
                        <InputLabel id="label-fab">{t("maintenance.select_fab")}</InputLabel>
                        <Select
                          value={selectManufactory}
                          labelId="label-Fab"
                          id="select-Fab"
                          onChange={(e) => setSelectManufactory(e.target.value)}
                          style={{ height: "3rem" }}
                          fullWidth
                        >
                          {Fab.map((p) => (
                            <MenuItem key={p.name} value={p.name}>
                              {p.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    ) : (
                      <FormControl variant="standard" fullWidth>
                        <MDInput
                          id="outlined-basic"
                          // onChange={handleChangePatio}
                          label={t("maintenance.select_lot")}
                          variant="standard"
                          fullWidth
                          type="text"
                          style={{ height: "2rem", marginTop: "10px" }}
                          value={selectLote}
                          onChange={(e) => setSelectLote(e.target.value)}
                        />
                      </FormControl>
                    )}
                  </Grid>

                  <Grid item xs={11} sm={11} md={6} lg={6} xl={6}>
                    {localSlct === "Container" ? (
                      <FormControl variant="standard" fullWidth>
                        <InputLabel id="label-quadra">
                          {t("maintenance.select_container")}
                        </InputLabel>
                        <Select
                          labelId="label-container"
                          id="select-container"
                          value={contrs}
                          onChange={handleChangeConts}
                          style={{ height: "3rem" }}
                          fullWidth
                        >
                          {contsList.map((p) => (
                            <MenuItem key={p.id} value={p.id}>
                              {p.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    {!validateDesc ? (
                      <MDBox style={{ height: "12rem" }}>
                        <TextField
                          id="outlined-basic"
                          label={t("common.description")}
                          onChange={(e) => {
                            setOnDescription(e.target.value);
                            setValidateDesc(false);
                          }}
                          variant="outlined"
                          multiline
                          rows={6}
                          fullWidth
                          value={onDescription}
                        />
                      </MDBox>
                    ) : (
                      <MDBox style={{ height: "12rem" }}>
                        <TextField
                          id="outlined-basic"
                          label={t("common.description")}
                          onChange={(e) => {
                            setOnDescription(e.target.value);
                            setValidateDesc(false);
                          }}
                          variant="outlined"
                          multiline
                          rows={6}
                          fullWidth
                          error
                        />
                      </MDBox>
                    )}
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
              <Card>
                <Grid container spacing={1} alignItems="flex-start" px={4}>
                  <Grid item ml={2} xs={12} sm={12} md={12} lg={6} xl={7}>
                    <FormControl variant="standard" fullWidth>
                      <InputLabel id="label-manutencao">
                        {t("maintenance.select_maintenance_type")}
                      </InputLabel>
                      <Select
                        labelId="label-manutencao"
                        id="select-manutencao"
                        value={maintenanceType}
                        onChange={handleChangeMaintenance}
                        style={{ height: "3rem" }}
                        fullWidth
                      >
                        {maintenanceList.map((p) => (
                          <MenuItem key={p.idMaintenanceType} value={p.idMaintenanceType}>
                            {p.description}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  {/* {console.log("list manutencao", maintenanceList)} */}
                  <Grid item mt={3} ml={2} xs={11} sm={11} md={11} lg={6} xl={4}>
                    <MDButton
                      variant="gradient"
                      color="info"
                      m={2}
                      onClick={() => {
                        handleOpenMaintenanceModal();
                      }}
                    >
                      {t("maintenance.buttom_maintenance_type")}
                    </MDButton>
                  </Grid>
                  <Grid item mt={1} ml={2} xs={12} sm={12} md={12} lg={12} xl={12}>
                    {rows.map((row, i) => (
                      <Grid item xs={12}>
                        <Grid container spacing={2} rowSpacing={4}>
                          <Grid item xs={11} sm={11} md={4} lg={6} xl={6} mt={-1}>
                            <FormControl variant="standard" fullWidth>
                              <InputLabel id="label-manutencao">
                                {t("maintenance.select_maintenance_materials")}
                              </InputLabel>
                              <Select
                                labelId="label-materials"
                                id="select-materials"
                                value={row.item}
                                onChange={(e) => handleUpdateRow(e, i, "item")}
                                style={{ height: "3rem" }}
                                fullWidth
                              >
                                {materialList.map((p) => (
                                  <MenuItem key={p.idmaterials} value={p.name}>
                                    {p.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={11} sm={11} md={2} lg={2} xl={2} mt={1.3}>
                            <TextField
                              value={row.quantity}
                              style={{ height: "4rem" }}
                              label={t("maintenance.input_qtd")}
                              variant="standard"
                              fullWidth
                              type="number"
                              onChange={(e) => handleUpdateRowNumber(e, i, "quantity")}
                            />
                          </Grid>

                          {/* <Grid item xs={11} sm={11} md={2} lg={2} xl={2}>
                            <TextField
                              value={row.value}
                              style={{ height: "3rem" }}
                              label={t("maintenance.input_value")}
                              variant="standard"
                              fullWidth
                              type="number"
                              onChange={(e) => handleUpdateRowNumber(e, i, "value")}
                            />
                          </Grid> */}
                          <Grid item xs={12} sm={12} md={4} lg={2} xl={1} mt={3}>
                            <Box
                              sx={{
                                border: "2px",
                                borderRadius: 3,
                              }}
                              display="flex"
                              alignItems="center"
                              style={{ height: "2rem", width: "2.5rem" }}
                              justifyContent="center"
                              onClick={() => {
                                if (rows.length === 1) return;
                                setRows(rows.filter((item) => item.id !== row.id));
                              }}
                            >
                              <ThemeProvider theme={theme}>
                                <Icon>delete_outlined</Icon>
                              </ThemeProvider>
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                  <Grid item ml={2} xs={11} sm={11} md={6} lg={4} xl={6}>
                    <MDButton variant="gradient" color="info" onClick={handleAddRow}>
                      {t("maintenance.buttom_material")}
                    </MDButton>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mt={5} mb={5}>
                    <Box
                      sx={{
                        border: !validatePhoto ? "2px dashed grey" : "2px dashed red",
                        borderRadius: 3,
                        bgcolor: "transparent",
                      }}
                      style={{ height: "10rem" }}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Dropzone onDrop={handleDrop}>
                        {({ getRootProps, getInputProps }) => (
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <ThemeProvider theme={theme}>
                              <Icon>camera_alt_outlined</Icon>
                            </ThemeProvider>
                          </div>
                        )}
                      </Dropzone>
                    </Box>
                  </Grid>
                  {nameImage.length > 0 ? (
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mt={2} mb={2}>
                      <ImageList
                        sx={{ width: 500, height: 350 }}
                        variant="quilted"
                        cols={5}
                        rowHeight={164}
                      >
                        {nameImage.map((e) => (
                          <ImageListItem key={e}>
                            <img src={e} alt={e} loading="lazy" />
                          </ImageListItem>
                        ))}
                      </ImageList>
                    </Grid>
                  ) : null}
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </Card>
      <MDBox
        mt={2}
        width="100%"
        justifyContent="flex-end"
        alignItems="center"
        sx={{ display: "flex" }}
      >
        <MDButton
          variant="gradient"
          color="info"
          size="large"
          style={{ marginLeft: 10 }}
          onClick={() => {
            handleCloseSaveModal(null, true);
          }}
          endIcon={<SaveIcon />}
        >
          {t("operational.but_save")}
        </MDButton>
        <MDButton
          variant="gradient"
          color="info"
          size="large"
          style={{ marginLeft: 10 }}
          onClick={() => {
            handleOpenSaveModal();
          }}
          endIcon={<SendIcon />}
        >
          {t("operational.but_save_send")}
        </MDButton>
        {isModalSave ? (
          <ModalAlerts
            title={t("operational.but_save")}
            users={users}
            contacts={contacts}
            onClose={handleCloseSaveModal}
            // patio={patioList.find((p) => p.idpatios === patio).name}
            patio={patioList.find((p) => p.idpatios === patio).idpatios}
          />
        ) : null}
      </MDBox>
    </SysDashLayout>
  );
}

export default SystemMaintenance;
