/* eslint-disable react/jsx-key */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
import * as React from "react";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import CloseIcon from "@mui/icons-material/Close";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import axios from "axios";
import ListSubheader from "@mui/material/ListSubheader";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Alert, IconButton } from "@mui/material";
import authHeader from "../../helpers/auth-headers";
import config from "../../config/config.json";
import SysDashLayout from "../../components_reefer/Layouts/SysDashLayout";
import Report from "../../components_reefer/Report/Report";
import MDBox from "../../components/MDBox";
import MDButton from "../../components/MDButton";
import MDTypography from "../../components/MDTypography";
import authService from "../../helpers/auth.service";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <MDBox sx={{ p: 3 }}>
          <MDTypography>{children}</MDTypography>
        </MDBox>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function SystemReports() {
  const yearList = [];
  for (let i = 2021; i <= new Date().getFullYear(); i += 1) {
    yearList.push(i);
  }

  const [patio, setPatio] = React.useState("");
  const [porto, setPorto] = React.useState("");
  const [patioList, setPatioList] = React.useState([]);
  const [portoList, setPortoList] = React.useState([]);
  const [reportMonth, setReportMonth] = React.useState();
  const [reportYear, setReportYear] = React.useState();
  const [reportFilter, setReportFilter] = React.useState("");
  const [reportData, setReportData] = React.useState({});
  const [reportDataKeys, setReportDataKeys] = React.useState([]);
  const [reportPeriod, setReportPeriod] = React.useState([]);
  const [valueMonit, setValueMonit] = React.useState([]);
  const [alertError, setAlertError] = React.useState([]);
  const [maintenanceData, setMaintenanceData] = React.useState([]);
  const [maintenanceMat, setMaintenanceMat] = React.useState([]);
  const [maintenanceType, setMaintenanceType] = React.useState([]);
  const [maintenanceValue, setMaintenanceValue] = React.useState([]);
  const [value, setValue] = React.useState(0);
  const user = authService.getCurrentUser();

  const { t } = useTranslation("common");
  const usr = authService.getCurrentUser();

  const handleChange = (event, newValue) => {
    setReportData({});
    setReportDataKeys([]);
    setMaintenanceData([]);
    setAlertError("");
    setValue(newValue);
  };

  const [initDate, setInitDate] = React.useState(moment().format("DD/MM/YYYY").toString());
  const [endDate, setEndDate] = React.useState(moment().format("DD/MM/YYYY").toString());

  const { API_URL } = config;

  const keys = Object.keys(maintenanceData);

  console.log("Usuário", user);
  React.useEffect(async () => {
    const fetchData = async () => {
      if (portoList.length === 0) {
        axios.get(`${API_URL}portos/${user.accountsIdaccounts}`, { headers: authHeader() }).then(
          (response) => {
            setPortoList(response.data);
            if (response.data.length === 1) {
              setPorto(response.data[0]);
              //  console.log(porto)
            }
          },
          (error) => {
            console.log(error);
          }
        );
      }
    };
    await fetchData();
    setReportMonth(new Date().getMonth() + 1);
    setReportYear(new Date().getFullYear());
  }, []);

  React.useEffect(() => {
    const fetchMaintType = async () => {
      if (maintenanceType.length === 0) {
        axios.get(`${API_URL}maintenance-type/`, { headers: authHeader() }).then(
          (response) => {
            setMaintenanceType(response.data);
          },
          (error) => {
            console.log(error);
          }
        );
      }
    };
    fetchMaintType();
  }, [maintenanceType]);

  React.useEffect(() => {
    const fetchData = async () => {
      if (porto.idportos > 0) {
        axios.get(`${API_URL}patios/${porto.idportos}`, { headers: authHeader() }).then(
          (response) => {
            setPatioList(response.data);
          },
          (error) => {
            console.log(error);
          }
        );
      }
    };
    fetchData();
  }, [porto]);

  React.useEffect(() => {
    const fetchPeriod = async () => {
      const tmpData = [];
      if (reportPeriod.length === 0) {
        await axios
          .get(`${API_URL}accounts/${user.accountsIdaccounts}`, { headers: authHeader() })
          .then(
            (response) => {
              tmpData.push(response.data);
            },
            (error) => {
              console.log(error);
            }
          );
        setReportPeriod(tmpData);
      }
    };
    fetchPeriod();
  }, []);

  React.useEffect(() => {
    const fetchValueMonit = async () => {
      const tmpData = [];
      if (valueMonit.length === 0) {
        await axios
          .get(`${API_URL}accounts/${user.accountsIdaccounts}`, { headers: authHeader() })
          .then(
            (response) => {
              tmpData.push(response.data);
            },
            (error) => {
              console.log(error);
            }
          );
        setValueMonit(tmpData);
      }
    };
    fetchValueMonit();
  }, []);

  React.useEffect(() => {
    const fetchMaintenance = async () => {
      if (maintenanceData.length === 0) {
        await axios.get(`${API_URL}maintenance-material`, { headers: authHeader() }).then(
          (response) => {
            setMaintenanceMat(response.data);
          },
          (error) => {
            console.log(error);
          }
        );
      }
    };
    fetchMaintenance();
  }, []);

  React.useEffect(() => {
    const fetchMaintenanceValue = async () => {
      if (maintenanceData.length === 0) {
        await axios
          .get(`${API_URL}accounts/${user.accountsIdaccounts}`, { headers: authHeader() })
          .then(
            (response) => {
              setMaintenanceValue(response.data);
            },
            (error) => {
              console.log(error);
            }
          );
      }
    };
    fetchMaintenanceValue();
  }, []);

  const requestReport = async () => {
    if (patio > 0 && reportFilter < 9) {
      setReportData({});
      setReportDataKeys([]);
      setMaintenanceData([]);
      setAlertError("");
      const dataParam = {
        init: initDate,
        end: endDate,
        patio,
        isErrOnly: reportFilter === 1 || reportFilter === 3 || reportFilter === 5,
        isMaintenance: reportFilter === 7 || reportFilter === 8,
        isQueries: false,
        isPdf: false,
      };
      const r = await axios.get(`${API_URL}reports`, { params: dataParam });
      console.log(dataParam);
      console.log(r.data);
      setMaintenanceData(r.data);
      setReportData(r.data);
      setReportDataKeys(Object.keys(r.data));
      console.log(dataParam);
    } else {
      setAlertError(2);
    }
  };
  const handleChangePorto = (event) => {
    setPorto({ idportos: event.target.value, name: event.target.name });
  };

  const handleChangePatio = (event) => {
    setPatio(event.target.value);
    setReportData({});
    setReportDataKeys([]);
    setMaintenanceData([]);
  };

  const handleChangeMonth = (event) => {
    setReportMonth(event.target.value);
    setInitDate(
      moment(`01/${event.target.value}/${reportYear}`, "DD/MM/YYYY").format("DD/MM/YYYY").toString()
    );
    setEndDate(
      moment(`01/${event.target.value}/${reportYear}`, "DD/MM/YYYY")
        .endOf("month")
        .format("DD/MM/YYYY")
        .toString()
    );
  };

  const handleChangeYear = (event) => {
    setReportYear(event.target.value);
    setInitDate(
      moment(`01/${reportMonth}/${event.target.value}`, "DD/MM/YYYY")
        .format("DD/MM/YYYY")
        .toString()
    );
    setEndDate(
      moment(`01/${reportMonth}/${event.target.value}`, "DD/MM/YYYY")
        .endOf("month")
        .format("DD/MM/YYYY")
        .toString()
    );
  };

  const handleChangeReportFilter = (event) => {
    setMaintenanceData([]);
    setReportData([]);
    setReportDataKeys([]);
    if (
      event.target.value === 3 ||
      event.target.value === 4 ||
      event.target.value === 6 ||
      event.target.value === 8
    ) {
      setInitDate(
        moment(`01/${reportMonth}/${reportYear}`, "DD/MM/YYYY").format("DD/MM/YYYY").toString()
      );
      setEndDate(
        moment(`01/${reportMonth}/${reportYear}`, "DD/MM/YYYY")
          .endOf("month")
          .format("DD/MM/YYYY")
          .toString()
      );
    } else {
      setInitDate(moment().format("DD/MM/YYYY").toString());
      setEndDate(moment().format("DD/MM/YYYY").toString());
    }
    setReportFilter(event.target.value);
  };

  // const tableReport = reportData;

  return (
    <SysDashLayout>
      {/* <Card style={{ justifyContent: "center", alignItems: "center" }}> */}
      <Card p={1}>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            {t("menu.rep")}
          </MDTypography>
        </MDBox>

        {user?.isOPAdmin === 1 ? (
          <MDBox pt={4} pb={3} px={3} sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab label={t("reports.tab_monitoring")} {...a11yProps(0)} />
            </Tabs>
          </MDBox>
        ) : null}
        {user?.isSysAdmin === 1 || user?.isAccountAdmin === 1 || user?.isFinancial === 1 ? (
          <MDBox pt={4} pb={3} px={3} sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab label={t("reports.tab_monitoring")} {...a11yProps(0)} />
              <Tab label={t("reports.tab_maintenance")} {...a11yProps(1)} />
              {/* <Tab label={t("reports.tab_dashboard")} {...a11yProps(2)} /> */}
            </Tabs>
          </MDBox>
        ) : null}
        <Box m={5} sx={{ width: "95%" }}>
          <TabPanel value={value} index={0}>
            <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6} p={1}>
                <FormControl variant="standard" sx={{ m: 1, p: 1, width: "95%" }}>
                  <InputLabel id="label-err">{t("reports.rep_filter_option")}</InputLabel>
                  <Select
                    labelId="label-err"
                    id="select-err"
                    value={reportFilter}
                    onChange={handleChangeReportFilter}
                    // value={age}
                    // label="Age"
                    style={{ height: "3rem" }}
                  >
                    <ListSubheader>{t("reports.rep_daily")}</ListSubheader>
                    <MenuItem value={1}>{t("reports.rep_err")}</MenuItem>
                    <MenuItem value={2}>{t("reports.rep_complete")}</MenuItem>
                    <ListSubheader>{t("reports.rep_monthly")}</ListSubheader>
                    <MenuItem value={3}>{t("reports.rep_err")}</MenuItem>
                    <MenuItem value={4}>{t("reports.rep_complete")}</MenuItem>
                    <ListSubheader>{t("reports.rep_period")}</ListSubheader>
                    <MenuItem value={5}>{t("reports.rep_err")}</MenuItem>
                    <MenuItem value={6}>{t("reports.rep_complete")}</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6} p={1}>
                <FormControl variant="standard" sx={{ m: 1, p: 1, width: "95%" }}>
                  <InputLabel id="label-err">{t("reports.rep_filter_option")}</InputLabel>
                  <Select
                    labelId="label-err"
                    id="select-err"
                    value={reportFilter}
                    onChange={handleChangeReportFilter}
                    // value={age}
                    // label="Age"
                    style={{ height: "3rem" }}
                  >
                    <MenuItem value={7}>{t("reports.rep_daily")}</MenuItem>
                    <MenuItem value={8}>{t("reports.rep_closure")}</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel>
            <Grid container>
              {portoList.length > 1 ? (
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6} p={1}>
                  <FormControl variant="standard" sx={{ m: 1, p: 1, width: "95%" }}>
                    <InputLabel id="label-porto">{t("reports.rep_harbor")}</InputLabel>
                    <Select
                      labelId="label-porto"
                      id="select-porto"
                      onChange={handleChangePorto}
                      // value={age}
                      // label="Age"
                      style={{ height: "3rem" }}
                    >
                      {portoList.map((p) => (
                        <MenuItem key={p.idportos} value={p.idportos}>
                          {p.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              ) : null}

              <Grid item xs={12} sm={12} md={12} lg={6} xl={6} p={1}>
                <FormControl variant="standard" sx={{ m: 1, p: 1, width: "95%" }}>
                  <InputLabel id="label-patio">{t("reports.rep_dock")}</InputLabel>
                  <Select
                    labelId="label-patio"
                    id="select-patio"
                    onChange={handleChangePatio}
                    // value={age}
                    // label="Age"
                    style={{ height: "3rem" }}
                  >
                    {patioList.map((p) => (
                      <MenuItem key={p.idpatios} value={p.idpatios}>
                        {p.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container>
              {reportFilter === 3 || reportFilter === 4 ? (
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6} p={1}>
                  <FormControl variant="standard" sx={{ m: 1, p: 1, width: "95%" }}>
                    <InputLabel id="label-err">{t("reports.rep_month")}</InputLabel>
                    <Select
                      labelId="label-err"
                      id="select-err"
                      value={reportMonth}
                      onChange={handleChangeMonth}
                      style={{ height: "3rem" }}
                    >
                      <MenuItem value="">
                        <em>{t("reports.rep_month")}</em>
                      </MenuItem>
                      <MenuItem value={1}>{t("reports.rep_january")}</MenuItem>
                      <MenuItem value={2}>{t("reports.rep_february")}</MenuItem>
                      <MenuItem value={3}>{t("reports.rep_march")}</MenuItem>
                      <MenuItem value={4}>{t("reports.rep_april")}</MenuItem>
                      <MenuItem value={5}>{t("reports.rep_may")}</MenuItem>
                      <MenuItem value={6}>{t("reports.rep_june")}</MenuItem>
                      <MenuItem value={7}>{t("reports.rep_july")}</MenuItem>
                      <MenuItem value={8}>{t("reports.rep_august")}</MenuItem>
                      <MenuItem value={9}>{t("reports.rep_september")}</MenuItem>
                      <MenuItem value={10}>{t("reports.rep_october")}</MenuItem>
                      <MenuItem value={11}>{t("reports.rep_november")}</MenuItem>
                      <MenuItem value={12}>{t("reports.rep_december")}</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              ) : null}
              {reportFilter === 3 || reportFilter === 4 ? (
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6} p={1}>
                  <FormControl variant="standard" sx={{ m: 1, p: 1, width: "95%" }}>
                    <InputLabel id="label-err">{t("reports.rep_year")}</InputLabel>
                    <Select
                      labelId="label-err"
                      id="select-err"
                      value={reportYear}
                      onChange={handleChangeYear}
                      style={{ height: "3rem" }}
                    >
                      {yearList.map((p) => (
                        <MenuItem value={p} key={p}>
                          {p}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              ) : null}
              {reportFilter === 1 || reportFilter === 2 ? (
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6} p={1}>
                  <FormControl sx={{ m: 1, p: 1, width: "95%" }}>
                    <DesktopDatePicker
                      label={t("reports.rep_date")}
                      inputFormat="DD/MM/yyyy"
                      disableFuture
                      minDate={moment(initDate, "DD/MM/YYYY").startOf("month")}
                      value={moment(initDate, "DD/MM/YYYY")}
                      onChange={(e) => {
                        setInitDate(e.format("DD/MM/YYYY").toString());
                        setEndDate(e.format("DD/MM/YYYY").toString());
                      }}
                      renderInput={(params) => (
                        <TextField variant="standard" {...params} value={initDate} />
                      )}
                      style={{ height: "3rem" }}
                    />
                  </FormControl>
                </Grid>
              ) : null}
              {reportFilter === 5 || reportFilter === 6 ? (
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6} p={1}>
                  <FormControl sx={{ m: 1, p: 1, width: "95%" }}>
                    <DesktopDatePicker
                      label={t("reports.rep_initial_date")}
                      inputFormat="DD/MM/yyyy"
                      value={moment(initDate, "DD/MM/YYYY")}
                      disableFuture
                      onChange={(e) => {
                        setInitDate(e.format("DD/MM/YYYY").toString());
                      }}
                      renderInput={(params) => <TextField variant="standard" {...params} />}
                      style={{ height: "3rem" }}
                    />
                  </FormControl>
                </Grid>
              ) : null}
              {reportFilter === 5 || reportFilter === 6 ? (
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6} p={1}>
                  {console.log("endDate", initDate)}
                  {console.log("endDate", endDate)}
                  <FormControl sx={{ m: 1, p: 1, width: "95%" }}>
                    <DesktopDatePicker
                      label={t("reports.rep_final_date")}
                      inputFormat="DD/MM/yyyy"
                      value={moment(endDate, "DD/MM/YYYY")}
                      onChange={(e) => {
                        setEndDate(e.format("DD/MM/YYYY").toString());
                      }}
                      minDate={moment(initDate, "DD/MM/YYYY")}
                      renderInput={(params) => <TextField variant="standard" {...params} />}
                      style={{ height: "3rem" }}
                    />
                  </FormControl>
                </Grid>
              ) : null}
              {reportFilter === 7 ? (
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6} p={1}>
                  <FormControl sx={{ m: 1, p: 1, width: "95%" }}>
                    <DesktopDatePicker
                      label={t("reports.rep_date")}
                      inputFormat="DD/MM/yyyy"
                      disableFuture
                      minDate={moment(initDate, "DD/MM/YYYY").startOf("month")}
                      value={moment(initDate, "DD/MM/YYYY")}
                      onChange={(e) => {
                        setInitDate(e.format("DD/MM/YYYY").toString());
                        setEndDate(e.format("DD/MM/YYYY").toString());
                      }}
                      renderInput={(params) => (
                        <TextField variant="standard" {...params} value={initDate} />
                      )}
                      style={{ height: "3rem" }}
                    />
                  </FormControl>
                </Grid>
              ) : null}
              {reportFilter === 8 ? (
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6} p={1}>
                  <FormControl variant="standard" sx={{ m: 1, p: 1, width: "95%" }}>
                    <InputLabel id="label-err">{t("reports.rep_month")}</InputLabel>
                    <Select
                      labelId="label-err"
                      id="select-err"
                      value={reportMonth}
                      onChange={handleChangeMonth}
                      style={{ height: "3rem" }}
                    >
                      <MenuItem value="">
                        <em>{t("reports.rep_month")}</em>
                      </MenuItem>
                      <MenuItem value={1}>{t("reports.rep_january")}</MenuItem>
                      <MenuItem value={2}>{t("reports.rep_february")}</MenuItem>
                      <MenuItem value={3}>{t("reports.rep_march")}</MenuItem>
                      <MenuItem value={4}>{t("reports.rep_april")}</MenuItem>
                      <MenuItem value={5}>{t("reports.rep_may")}</MenuItem>
                      <MenuItem value={6}>{t("reports.rep_june")}</MenuItem>
                      <MenuItem value={7}>{t("reports.rep_july")}</MenuItem>
                      <MenuItem value={8}>{t("reports.rep_august")}</MenuItem>
                      <MenuItem value={9}>{t("reports.rep_september")}</MenuItem>
                      <MenuItem value={10}>{t("reports.rep_october")}</MenuItem>
                      <MenuItem value={11}>{t("reports.rep_november")}</MenuItem>
                      <MenuItem value={12}>{t("reports.rep_december")}</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              ) : null}
              {reportFilter === 8 ? (
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6} p={1}>
                  <FormControl variant="standard" sx={{ m: 1, p: 1, width: "95%" }}>
                    <InputLabel id="label-err">{t("reports.rep_year")}</InputLabel>
                    <Select
                      labelId="label-err"
                      id="select-err"
                      value={reportYear}
                      onChange={handleChangeYear}
                      style={{ height: "3rem" }}
                    >
                      {yearList.map((p) => (
                        <MenuItem value={p} key={p}>
                          {p}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              ) : null}
            </Grid>
            <Grid container style={{ justifyContent: "left", alignItems: "cente" }}>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6} p={1}>
                <FormControl
                  sx={{ m: 1, p: 1, width: "50%" }}
                  style={{ justifyContent: "center", alignItems: "center" }}
                >
                  <MDButton
                    sx={{ width: "80%" }}
                    variant="gradient"
                    color="info"
                    style={{ height: "3rem" }}
                    onClick={requestReport}
                  >
                    {reportFilter < 9 ? t("reports.rep_generate") : t("reports.rep_submit")}
                  </MDButton>
                </FormControl>
              </Grid>
            </Grid>
          </TabPanel>
        </Box>

        {alertError === 1 ? (
          <Grid container mt={-30} style={{ position: "fixed" }}>
            <Grid xs={11} item>
              <Box style={{ display: "flex", justifyContent: "right" }} className="text-title">
                <Alert
                  action={
                    <IconButton
                      aria-label="close"
                      size="small"
                      color="inherit"
                      onClick={() => {
                        setAlertError(0);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                  severity="error"
                >
                  {t("Nenhum dado para mostrar!")}
                </Alert>
              </Box>
            </Grid>
          </Grid>
        ) : null}
        {alertError === 2 ? (
          <Grid container mt={-30} style={{ position: "fixed" }}>
            <Grid xs={11} item>
              <Box style={{ display: "flex", justifyContent: "right" }} className="text-title">
                <Alert
                  action={
                    <IconButton
                      aria-label="close"
                      size="small"
                      color="inherit"
                      onClick={() => {
                        setAlertError(0);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                  severity="error"
                >
                  {t("Preencha todos os campos!")}
                </Alert>
              </Box>
            </Grid>
          </Grid>
        ) : null}
      </Card>
      {(reportFilter === 4 && reportDataKeys.length > 0 && usr.isFinancial === 1) ||
      (reportFilter === 4 && reportDataKeys.length > 0 && usr.isSysAdmin === 1) ? (
        <Report
          ship="all"
          reportData={reportData}
          keys={reportDataKeys}
          InitDate={initDate}
          EndDate={endDate}
          valuePerMonitoring={valueMonit.find((p) => p.monitoringValue).monitoringValue}
          patio={patioList.find((p) => p.idpatios === patio).name}
        />
      ) : null}
      {reportFilter === 4 && reportDataKeys.length > 0
        ? reportDataKeys.map((key) => (
            <Report
              ship={key}
              isMonthly={reportFilter === 4}
              reportData={reportData[key]}
              reportAll={reportData}
              InitDate={initDate}
              EndDate={endDate}
              PeriodAM={reportPeriod.find((p) => p.monitoringByAM).monitoringByAM}
              PeriodPM={reportPeriod.find((p) => p.monitoringByPM).monitoringByPM}
              valuePerMonitoring={valueMonit.find((p) => p.monitoringValue).monitoringValue}
              patio={patioList.find((p) => p.idpatios === patio).name}
            />
          ))
        : null}
      {reportFilter === 3 && reportDataKeys.length > 0
        ? reportDataKeys.map((key) => (
            <Report
              ship={key}
              isMonthly={reportFilter === 3}
              reportData={reportData[key]}
              InitDate={initDate}
              EndDate={endDate}
              PeriodAM={reportPeriod.find((p) => p.monitoringByAM).monitoringByAM}
              PeriodPM={reportPeriod.find((p) => p.monitoringByPM).monitoringByPM}
              valuePerMonitoring={valueMonit.find((p) => p.monitoringValue).monitoringValue}
              patio={patioList.find((p) => p.idpatios === patio).name}
            />
          ))
        : null}
      {reportFilter === 1 || (reportFilter === 2 && reportDataKeys.length > 0)
        ? reportDataKeys.map((key) => (
            <Report
              type="reportDaily"
              ship={key}
              isMonthly={reportFilter === 4}
              reportData={reportData[key]}
              InitDate={initDate}
              EndDate={endDate}
              PeriodAM={reportPeriod.find((p) => p.monitoringByAM).monitoringByAM}
              PeriodPM={reportPeriod.find((p) => p.monitoringByPM).monitoringByPM}
              valuePerMonitoring={valueMonit.find((p) => p.monitoringValue).monitoringValue}
              patio={patioList.find((p) => p.idpatios === patio).name}
            />
          ))
        : null}

      {reportFilter === 7 && reportDataKeys.length > 0
        ? keys.map((key) => (
            <Report
              maintenance="daily"
              keys={key}
              InitDate={initDate}
              EndDate={endDate}
              patio={patioList.find((p) => p.idpatios === patio)}
              data={maintenanceData[key]}
              maintenanceValue={maintenanceValue.maintenanceValue}
              total={maintenanceValue}
            />
          ))
        : null}
      {reportFilter === 8 && reportDataKeys.length > 0 ? (
        <Report
          maintenance="closing"
          keys={reportDataKeys}
          InitDate={initDate}
          EndDate={endDate}
          patio={patioList.find((p) => p.idpatios === patio)}
          data={maintenanceData}
          Mat={maintenanceMat}
          maintenanceValue={maintenanceValue.maintenanceValue}
        />
      ) : null}
      {reportFilter === 8 && reportDataKeys.length > 0
        ? keys.map((key) => (
            <Report
              maintenance="OS"
              keys={key}
              InitDate={initDate}
              EndDate={endDate}
              patio={patioList.find((p) => p.idpatios === patio)}
              data={maintenanceData[key]}
              maintenanceValue={maintenanceValue.maintenanceValue}
              total={maintenanceValue}
            />
          ))
        : null}
      {reportFilter === 6 && reportDataKeys.length > 0
        ? reportDataKeys.map((key) => (
            <Report
              ship={key}
              isMonthly={reportFilter === 6}
              reportData={reportData[key]}
              InitDate={initDate}
              EndDate={endDate}
              PeriodAM={reportPeriod.find((p) => p.monitoringByAM).monitoringByAM}
              PeriodPM={reportPeriod.find((p) => p.monitoringByPM).monitoringByPM}
              valuePerMonitoring={valueMonit.find((p) => p.monitoringValue).monitoringValue}
              patio={patioList.find((p) => p.idpatios === patio).name}
            />
          ))
        : null}
    </SysDashLayout>
  );
}

export default SystemReports;
