/* eslint-disable react/prop-types */
/* eslint-disable global-require */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/function-component-definition */
/* eslint-disable no-nested-ternary */
// package imports
import React, { useEffect, useState } from "react";
import { Grid, ImageList, ImageListItem, Button, IconButton } from "@mui/material";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Icon from "@mui/material/Icon";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Stack from "@mui/material/Stack";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";

export default function ContainerMonitoringCard(itemOrigin) {
  const [open, setOpen] = React.useState(false);
  const [item, setItem] = useState(itemOrigin);
  // console.log("itemorigin", itemOrigin);
  const onChange = (field, v) => {
    const newItem = { ...item };
    newItem.itemOrigin[field] = v;
    const temps = [newItem.itemOrigin.current, newItem.itemOrigin.setpoint];
    const isErr = Math.max(...temps) - Math.min(...temps) < 4;
    // console.log("temps", temps);
    // console.log("isErr", isErr);
    // console.log("field", field);

    if (Math.max(...temps) - Math.min(...temps) === 3 && field === "current") {
      newItem.itemOrigin.isErr = 1;
    } else if (
      Math.max(...temps) - Math.min(...temps) > 3 &&
      field === "current" &&
      newItem.itemOrigin.isErr < 2
    ) {
      newItem.itemOrigin.isErr = 2;
    } else if (field === "current" && isErr) {
      newItem.itemOrigin.isErr = 0;
    }
    // console.log("erro do container", newItem.itemOrigin.isErr);
    setItem(newItem);
    item.onChangeParent(newItem, newItem.index);
  };
  const { t } = useTranslation("common");

  const url = "https://s3.sa-east-1.amazonaws.com/report-assets.reefersys.com/";

  const handleFileInput = (e) => {
    item.handleFileInputParent(e, item.itemOrigin, item.index);
  };

  const onItemDelete = () => {
    onChange("isDelete", !item.itemOrigin.isDelete);
    item.onDeleteParent(item.itemOrigin);
    setOpen(false);
  };

  const handleDeleteImage = (index) => {
    item.handleDeleteInputParent(index, item.index);
  };

  const onItemUpdate = () => {
    onChange("isCheck", !item.itemOrigin.isCheck);
    // item.onUpdateParent(item.itemOrigin);
  };

  useEffect(() => {}, [setOpen]);
  const theme = createTheme({
    components: {
      MuiIcon: {
        styleOverrides: {
          root: {
            fontSize: "3rem",
            color: "#fff",
          },
        },
      },
    },
  });

  const themePreventive = createTheme({
    components: {
      MuiIcon: {
        styleOverrides: {
          root: {
            fontSize: "2rem",
            color: "#fff",
          },
        },
      },
    },
  });

  const themePreventiveTwo = createTheme({
    components: {
      MuiIcon: {
        styleOverrides: {
          root: {
            fontSize: "3rem",
            color: "#fff",
          },
        },
      },
    },
  });

  const themeRed = createTheme({
    components: {
      MuiIcon: {
        styleOverrides: {
          root: {
            fontSize: "3rem",
            color: "red",
          },
        },
      },
    },
  });

  const themeRedPreventive = createTheme({
    components: {
      MuiIcon: {
        styleOverrides: {
          root: {
            fontSize: "2rem",
            color: "red",
          },
        },
      },
    },
  });

  const themeYellow = createTheme({
    components: {
      MuiIcon: {
        styleOverrides: {
          root: {
            fontSize: "3rem",
            color: "yellow",
          },
        },
      },
    },
  });

  const themeYellowPreventive = createTheme({
    components: {
      MuiIcon: {
        styleOverrides: {
          root: {
            fontSize: "2rem",
            color: "yellow",
          },
        },
      },
    },
  });

  const themeOcean = createTheme({
    components: {
      MuiIcon: {
        styleOverrides: {
          root: {
            fontSize: "3rem",
            color: "#a6dced",
          },
        },
      },
    },
  });

  const themeOceanPreventive = createTheme({
    components: {
      MuiIcon: {
        styleOverrides: {
          root: {
            fontSize: "2rem",
            color: "#a6dced",
          },
        },
      },
    },
  });

  const themeDark = createTheme({
    components: {
      MuiIcon: {
        styleOverrides: {
          root: {
            fontSize: "3rem",
            color: "#000",
          },
        },
      },
    },
  });

  const themeDarkPreventive = createTheme({
    components: {
      MuiIcon: {
        styleOverrides: {
          root: {
            fontSize: "2rem",
            color: "#000",
          },
        },
      },
    },
  });

  const themeTwo = createTheme({
    components: {
      MuiIcon: {
        styleOverrides: {
          root: {
            fontSize: "3.5rem",
            color: "#000",
          },
        },
      },
    },
  });

  // const themeIconSmall = createTheme({
  //   components: {
  //     MuiIcon: {
  //       styleOverrides: {
  //         root: {
  //           alignItems: "center",
  //           fontSize: "1.5rem",
  //           color: "#000",
  //         },
  //       },
  //     },
  //   },
  // });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePreventive = () => {
    onChange("isPreventive", !item.itemOrigin.isPreventive);
    // console.log("container", item.itemOrigin.label, "isPreventive", item.itemOrigin.isPreventive);
    // console.log("item", item);
  };

  const MaintenanceIcon = ({ size }) => (
    <img
      src={require("../../../assets/images/icons/maintenance.png")} // Certifique-se de ajustar o caminho da imagem conforme necessário
      alt="Ícone"
      style={{
        width: size,
        height: size,
        filter: "brightness(0) invert(1)",
      }}
    />
  );

  return (
    <Grid item xs={12} sm={12} md={6} lg={4} xl={3} my={2}>
      <Card
        my={1}
        style={{
          backgroundColor: "white",
          justifyContent: "center",
          alignItems: "center",
        }}
        pt={1}
      >
        <MDBox style={{ Width: "100%" }}>
          <Grid container style={{ justifyContent: "center", alignItems: "center" }}>
            <MDBox ml={2} my={3} onClick={onItemUpdate} sx={{ cursor: "pointer" }}>
              {item.itemOrigin.isCheck ? (
                <Box
                  sx={{ border: "2px dashed grey", borderRadius: 3, bgcolor: "#088923" }}
                  style={{ height: "4.5rem", width: "4.5rem" }}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <ThemeProvider theme={theme}>
                    <Icon>check_circle_outline</Icon>
                  </ThemeProvider>
                </Box>
              ) : (
                <Box
                  sx={{ border: "2px dashed grey", borderRadius: 3, bgcolor: "#ffffff" }}
                  style={{ height: "4.5rem", width: "4.5rem" }}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <ThemeProvider theme={theme}>
                    <Icon>check_circle_outline</Icon>
                  </ThemeProvider>
                </Box>
              )}
            </MDBox>
            <MDBox mx={2} my={3} sx={{ cursor: "pointer" }}>
              <ThemeProvider theme={themeTwo}>
                <Button
                  component="label"
                  variant="text"
                  onClick={() => {
                    // onItemDelete();
                    handleClickOpen();
                  }}
                >
                  <Icon>delete_outline_outlined</Icon>
                </Button>
              </ThemeProvider>
            </MDBox>
            <MDBox mr={2} my={3} sx={{ cursor: "pointer" }}>
              <ThemeProvider theme={themeTwo}>
                <Button component="label" variant="text">
                  {/* {console.log(item.itemOrigin.photos.lenght )} */}
                  {item.itemOrigin.photos.length > 0 ? (
                    <Icon style={{ color: "green" }}>camera_alt_outlined</Icon>
                  ) : (
                    <Icon>camera_alt_outlined</Icon>
                  )}
                  <input
                    type="file"
                    hidden
                    onChange={(ev) => {
                      handleFileInput(ev);
                      console.log("photo:", ev);
                    }}
                  />
                </Button>
              </ThemeProvider>
            </MDBox>
          </Grid>
        </MDBox>
        {console.log("teste photos", item.itemOrigin.photos.length)}
        {item.itemOrigin.photos.length > 0 && (
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mt={2} mb={0}>
            <ImageList
              sx={{
                display: "flex",
                flexWrap: "wrap",
                width: "100%",
                height: 200,
                justifyContent: "center",
              }}
              variant="quilted"
              cols={2}
              rowHeight={164}
            >
              {item.itemOrigin.photos.map((e, index) => (
                <ImageListItem key={e}>
                  <img src={url + e} alt={e} loading="lazy" />
                  <IconButton
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      color: "white",
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                    }}
                    onClick={() => handleDeleteImage(index)} // Adicione a função de exclusão
                  >
                    <Icon>delete_outlined</Icon>
                  </IconButton>
                </ImageListItem>
              ))}
            </ImageList>
          </Grid>
        )}
        {item.itemOrigin.isErr > 3 ? (
          <MDBox my={2} ml={-2} width="100%" justifyContent="center" alignItems="center">
            <Grid
              container
              spacing={0.5}
              bgColor="yellow"
              justifyContent="center"
              style={{ justifyContent: "center", alignItems: "center" }}
            >
              <Grid
                item
                xs={3}
                sm={3}
                md={3}
                lg={3}
                xl={3}
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  const e = item.itemOrigin.isErr === 4 ? 0 : 4;
                  onChange("isErr", e);
                }}
              >
                <Box
                  sx={{
                    border: "2px grey",
                    borderRadius: 3,
                    bgcolor: item.itemOrigin.isErr === 4 ? "#030303" : "darkgrey",
                  }}
                  display="flex"
                  alignItems="center"
                  style={{ height: "4.5rem", width: "4.5rem" }}
                  justifyContent="center"
                >
                  {item.itemOrigin.isErr === 4 && item.itemOrigin.isPreventive ? (
                    <ThemeProvider theme={themeRedPreventive}>
                      <MaintenanceIcon size={32} />
                      <Icon>notification_important_outlined</Icon>
                    </ThemeProvider>
                  ) : item.itemOrigin.isErr === 4 ? (
                    <ThemeProvider theme={themeRed}>
                      <Icon>notification_important_outlined</Icon>
                    </ThemeProvider>
                  ) : (
                    <ThemeProvider theme={theme}>
                      <Icon>notification_important_outlined</Icon>
                    </ThemeProvider>
                  )}
                </Box>
              </Grid>
              <Grid
                item
                xs={3}
                sm={3}
                md={3}
                lg={3}
                xl={3}
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  const e = item.itemOrigin.isErr === 6 ? 0 : 6;
                  onChange("isErr", e);
                }}
              >
                <Box
                  sx={{
                    border: "2px grey",
                    borderRadius: 3,
                    bgcolor: item.itemOrigin.isErr === 6 ? "#030303" : "darkgrey",
                  }}
                  display="flex"
                  alignItems="center"
                  style={{ height: "4.5rem", width: "4.5rem" }}
                  justifyContent="center"
                >
                  {item.itemOrigin.isErr === 6 && item.itemOrigin.isPreventive ? (
                    <ThemeProvider theme={themeYellowPreventive}>
                      <MaintenanceIcon size={32} />
                      <Icon>power_off_outlined</Icon>
                    </ThemeProvider>
                  ) : item.itemOrigin.isErr === 6 ? (
                    <ThemeProvider theme={themeYellow}>
                      <Icon>power_off_outlined</Icon>
                    </ThemeProvider>
                  ) : (
                    <ThemeProvider theme={theme}>
                      <Icon>power_off_outlined</Icon>
                    </ThemeProvider>
                  )}
                </Box>
              </Grid>
              <Grid
                item
                xs={3}
                sm={3}
                md={3}
                lg={3}
                xl={3}
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  const e = item.itemOrigin.isErr === 8 ? 0 : 8;
                  onChange("isErr", e);
                }}
              >
                <Box
                  sx={{
                    border: "2px grey",
                    borderRadius: 3,
                    bgcolor: item.itemOrigin.isErr === 8 ? "#030303" : "darkgrey",
                  }}
                  display="flex"
                  alignItems="center"
                  style={{ height: "4.5rem", width: "4.5rem" }}
                  justifyContent="center"
                >
                  {item.itemOrigin.isErr === 8 && item.itemOrigin.isPreventive ? (
                    <ThemeProvider theme={themeOceanPreventive}>
                      <MaintenanceIcon size={32} />
                      <Icon>outlet_outlined</Icon>
                    </ThemeProvider>
                  ) : item.itemOrigin.isErr === 8 ? (
                    <ThemeProvider theme={themeOcean}>
                      <Icon>outlet_outlined</Icon>
                    </ThemeProvider>
                  ) : (
                    <ThemeProvider theme={theme}>
                      <Icon>outlet_outlined</Icon>
                    </ThemeProvider>
                  )}
                </Box>
              </Grid>
            </Grid>
          </MDBox>
        ) : (
          <MDBox style={{ Width: "100%" }}>
            <Grid container style={{ justifyContent: "center", alignItems: "center" }}>
              <MDBox
                ml={2}
                my={2}
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  onChange("isErr", 4);
                }}
              >
                {(item.itemOrigin.current - item.itemOrigin.setpoint === 3 ||
                  item.itemOrigin.setpoint - item.itemOrigin.current === 3) &&
                item.itemOrigin.isPreventive ? (
                  <Box
                    sx={{ border: "2px grey", borderRadius: 3, bgcolor: "yellow" }}
                    style={{ height: "4.5rem", width: "4.5rem" }}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <ThemeProvider theme={themeDarkPreventive}>
                      <MaintenanceIcon size={32} />
                      <Icon>warning_amber</Icon>
                    </ThemeProvider>
                  </Box>
                ) : item.itemOrigin.current - item.itemOrigin.setpoint === 3 ||
                  item.itemOrigin.setpoint - item.itemOrigin.current === 3 ? (
                  <Box
                    sx={{ border: "2px grey", borderRadius: 3, bgcolor: "yellow" }}
                    style={{ height: "4.5rem", width: "4.5rem" }}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <ThemeProvider theme={themeDark}>
                      <Icon>warning_amber</Icon>
                    </ThemeProvider>
                  </Box>
                ) : (item.itemOrigin.current - item.itemOrigin.setpoint > 3 ||
                    item.itemOrigin.setpoint - item.itemOrigin.current > 3) &&
                  item.itemOrigin.isPreventive ? (
                  <Box
                    sx={{ border: "2px grey", borderRadius: 3, bgcolor: "red" }}
                    style={{ height: "4.5rem", width: "4.5rem" }}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <ThemeProvider theme={themePreventive}>
                      <MaintenanceIcon size={32} />
                      <Icon>highlight_off</Icon>
                    </ThemeProvider>
                  </Box>
                ) : item.itemOrigin.current - item.itemOrigin.setpoint > 3 ||
                  item.itemOrigin.setpoint - item.itemOrigin.current > 3 ? (
                  <Box
                    sx={{ border: "2px grey", borderRadius: 3, bgcolor: "red" }}
                    style={{ height: "4.5rem", width: "4.5rem" }}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <ThemeProvider theme={theme}>
                      <Icon>highlight_off</Icon>
                    </ThemeProvider>
                  </Box>
                ) : item.itemOrigin.isPreventive ? (
                  <Box
                    sx={{ border: "2px grey", borderRadius: 3, bgcolor: "#9b24ff" }}
                    style={{ height: "4.5rem", width: "4.5rem" }}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <ThemeProvider theme={themePreventiveTwo}>
                      <MaintenanceIcon size={48} />
                    </ThemeProvider>
                  </Box>
                ) : (
                  <Box
                    sx={{ border: "2px grey", borderRadius: 3, bgcolor: "info.main" }}
                    style={{ height: "4.5rem", width: "4.5rem" }}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <ThemeProvider theme={theme}>
                      <Icon>check_circle_outline</Icon>
                    </ThemeProvider>
                  </Box>
                )}
              </MDBox>
              <MDBox mx={2} my={2} style={{ fontSize: "40px" }}>
                {item.itemOrigin.current}
              </MDBox>
              <Stack
                direction="row"
                spacing={1}
                style={{ justifyContent: "center", alignItems: "center" }}
              >
                <Icon
                  fontSize="large"
                  onClick={() => {
                    onChange("current", item.itemOrigin.current + 1);
                  }}
                  sx={{ color: "info.main", cursor: "pointer" }}
                >
                  add_circle
                </Icon>
                <Icon
                  fontSize="large"
                  onClick={() => {
                    onChange("current", item.itemOrigin.current - 1);
                  }}
                  sx={{ color: "info.main", cursor: "pointer" }}
                >
                  do_disturb_on
                </Icon>
              </Stack>
              {item.itemOrigin.current - item.itemOrigin.setpoint > 3 ||
              item.itemOrigin.setpoint - item.itemOrigin.current > 3 ? (
                <MDBox
                  my={2}
                  width="100%"
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue={0}
                      name="radio-buttons-group"
                      onChange={(e) => {
                        onChange("isErr", e.target.value);
                      }}
                      // value={retroType}
                    >
                      <FormControlLabel value={2} control={<Radio />} label="coolingdown" />
                      <FormControlLabel value={3} control={<Radio />} label="defrost" />
                    </RadioGroup>
                  </FormControl>
                </MDBox>
              ) : null}
            </Grid>
          </MDBox>
        )}
        <MDTypography style={{ fontSize: "30px" }} color="#000" mt={1} ml={1} mr={1}>
          {item.itemOrigin.containers_label}
        </MDTypography>
        <Divider />
        <MDBox mt={-2} width="85%" style={{ height: "6rem" }}>
          <TextField
            label={t("common.description")}
            variant="outlined"
            multiline
            InputProps={{
              inputComponent: "textarea",
            }}
            rows={3}
            fullWidth
            value={item.itemOrigin.notes}
            onChange={(e) => {
              onChange("notes", e.nativeEvent.target.value);
            }}
          />
        </MDBox>
        <MDBox
          my={2}
          width="100%"
          style={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <FormControl>
            <FormGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue={0}
              name="radio-buttons-group"
              onChange={handlePreventive}
            >
              <FormControlLabel
                value={1}
                required
                control={<Checkbox />}
                label="Reparo Preventivo"
              />
            </FormGroup>
          </FormControl>
        </MDBox>
        <Dialog
          open={open}
          // onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{t("common.delete")}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {t("common.delete_text")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>{t("alerts.cancel")}</Button>
            <Button onClick={onItemDelete} autoFocus>
              {t("alerts.agree")}
            </Button>
          </DialogActions>
        </Dialog>
      </Card>
    </Grid>
  );
}
