/* eslint-disable no-use-before-define */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import * as React from "react";
import { useTranslation } from "react-i18next";
import DataTable from "../../Tables/DataTable";
import formatDate from "../../../helpers/formatDateMaintenance";

export default function MaintenanceClosing(props) {
  const { t } = useTranslation("common");
  const [rows, setRows] = React.useState([]);
  const [tmpValue, setTmpValue] = React.useState([]);
  const [value] = React.useState([]);
  console.log("PROPS CLOSING=========>:", props);
  const columns = [
    { Header: t("common.date"), accessor: "date", align: "left" },
    { Header: t("common.ctnr"), accessor: "container", align: "left" },
    { Header: t("modal.mod_input_value"), accessor: "value", align: "left" },
  ];

  React.useEffect(() => {
    if (value.length === 0) {
      const data = props?.content.data;
      const keysData = Object.keys(props?.content.data);
      const tmpRow = [];
      const tempValue = [];
      keysData.forEach((keyData) => {
        tmpRow.push(data[keyData].material_item);
      });
      tmpRow.forEach((e) => {
        tempValue.push(e);
      });
      setTmpValue(tempValue);
    }
  }, []);

  React.useEffect(() => {
    if (rows.length === 0) {
      const tmpRow = [];
      const keys = Object.keys(props?.content.data);
      keys.forEach((key) => {
        const data = props?.content.data[key].execution_date;
        const type = props?.content.data[key].material_item;
        const valores = type;
        const val = valores.reduce(getTotal, 0);
        function getTotal(total, produto) {
          const somaTotal = total + produto.value * produto.quantity;
          return somaTotal;
        }
        const Maintenance = props?.content.maintenanceValue;
        tmpRow.push({
          date: formatDate(data),
          container: props?.content.data[key].container,
          value: `R$ ${val + Maintenance}`,
        });
      });
      setRows(tmpRow);
      console.log(tmpRow);
    }
  }, [rows]);

  console.log(tmpValue);
  return (
    <div style={{ width: "100%" }}>
      <DataTable
        table={{ columns, rows }}
        isSorted={false}
        entriesPerPage={false}
        showTotalEntries={false}
        noEndBorder
      />
    </div>
  );
}
